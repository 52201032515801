<template>

  <v-dialog
  tile
    v-model="show"
    persistent
    fullscreen>
    <v-card tile>
      <v-toolbar dense flat color="secondary" dark>
        <v-icon  left color="info">mdi-information</v-icon>Event Entries
        <v-spacer/>
           <v-btn x-small fab rounded  @click="emitToParent('ok')"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
        <v-card-text class="pa-4">
          <v-row>
          <v-col cols="6" class="pa-1" v-for="(item,index) in entries" :key="index">
            <v-btn  elevation="1" small tile block @click="selectEntry(item)">
            {{item.name}}
          </v-btn>
        </v-col>
      </v-row>
                       
       
        
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    entries: {
        type: Array,
        default: ()=>{}
    },
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
