<template>
     <v-card :class="color">
              <v-card-text style="min-height: 60px;" class="text-center pa-1">
                <div class="ev-title font-weight-black text-primary">
                      {{data.name}} 
                   </div>
                  <div class="ev-date">{{$moment(data.event_date).format("MMM, DD, YYYY")}} |  <span class="ev-venue">{{data.venue}}</span></div>
                  
                   <div class="ev-status font-weight-bold font-italic" :class="STATUS_COLOR(data.status)">Status: {{data.status}}</div>
                   <small class="font-italic text-primary">{{data.status=='Closed'?"Event Ongoing":""  }}</small>
                   
                </v-card-text>
                <v-card-actions class="pa-2">
                  <v-divider color="gold"/>
                  <v-spacer/>
                  <v-btn color="tertiary" x-small rounded class="btn-grad-primary"  dark @click="$router.push('/betting/'+data.id)" >
                     {{data.status=='Closed'?"Standings":"View Event"  }} <v-icon right small>mdi-arrow-right</v-icon>
                    </v-btn>
                  <v-spacer/>
                   <v-divider color="gold"/>
                </v-card-actions>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: ()=>{}
    },
    sup: {
      type: String,
      default: ''
    }
  },
  computed:{
    NUM(){
        var n = this.data.combination.split(' - ')
        return n
    }
  },
  methods: {

  }

}
</script>