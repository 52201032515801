<template>
 <v-dialog
    v-model="show"
    persistent
    width="370"
    >

             <v-card>
              <v-toolbar dense  class="pa-1" color="primary" dark>
                Your  Bet
                <!-- <v-chip color="success"> {{event.status}}</v-chip> -->
                 <v-spacer/>
                <v-icon @click="emitToParent('cancel')">mdi-close-circle</v-icon>
              </v-toolbar>
              <v-card-text>
                <!-- <p class="text-warning text-center" v-if="event.status=='Open'"> Review your bet!</p> -->
                  <table class="va-table mt-1  va-table-border-bot" cellspacing="0">
                      <tr>
                        <th class="text-left" style="padding: 4px">EVENT:</th>
                        <th class="text-left" style="padding: 4px">{{event.name}}</th>
                      </tr>
                      <tr>
                        <th class="text-left" style="padding: 4px">DATE:</th>
                        <th class="text-left" style="padding: 4px">{{$moment(event.event_date).format("MMM, DD YYYY")}} </th>
                      </tr>
                      <tr>
                        <th class="text-left" style="padding: 4px">VENUE:</th>
                        <th class="text-left" style="padding: 4px">{{event.venue}}</th>
                      </tr>
                      <tr>
                        <th class="text-left" style="padding: 4px">PICK:</th>
                        <th class="text-left" style="padding: 4px"> {{bet.pick}}</th>
                      </tr>
                      <tr>
                        <th class="text-left" style="padding: 4px">BRACKET:</th>
                        <th class="text-left" style="padding: 4px"> {{bet.bracket}}</th>
                      </tr>
                      <tr>
                        <th class="text-left" style="padding: 4px">BET:</th>
                        <th class="text-left" style="padding: 4px"> {{$money(bet.amount)}} PHP</th>
                      </tr>
                      <tr>
                        <th class="text-left" style="padding: 4px">ENTRIES:</th>
                        <th class="text-left" style="padding: 4px"> {{bet.entry_names}}</th>
                      </tr>
                  </table>
               
                </v-card-text>  
                <v-card-actions>
                  <v-spacer/>
                  <v-btn @click="emitToParent('close')">Close</v-btn>
                  
                  <!-- <v-btn color="success" @click="emitToParent('bet')">Proceed</v-btn> -->
                </v-card-actions>
             </v-card>
         

 </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
       event_id: {
        type: Number,
        default: ()=>0
      },
      bracket: {
        type: String,
        default: ()=> ""
      },
      bet: {
        type: Object,
        default: ()=> {}
      },
      pick: {
        type: String,
        default: ()=> ""
      },
      betid: {
        type: Number,
        default: ()=> 0
      }
    },
    data: () => ({
      ticket: {},
      to_menu: false,
      viewticket: false,
      to_date:'',
      confirm: false,
      cancelbtn: 'No',
      okbtn: "Ok",
      confirmText: "",
      action:'',
      event: {},
      bets:[],
      entries: []
    }),
    // created() {
    //   this.PING()
    // },
    //  mounted(){
    //    this.setLoggedIn(true)
    //     if (this.user == null) {
    //       this.$router.push('/')
    //     }else {
    //       this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
    //       this.to_date = this.$moment().format("YYYY-MM-DD")
    //       this.setData()
    //     }
    // },
    watch: {
      show(v) {
          if(v) {
            this.event = {}
            this.bets = []
            this.entries = []
            this.getEvent()
          }
      }
    },
    computed:{
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      BYBRACKETS() {
        var allbets = this.$groupBy('bracket')
        return allbets(this.bets)
       },
       ALLBETS() {
        var _bets = this.bets.reduce((res, item)=>{
          if(item.pick == this.pick && item.bracket == this.bracket) {
            res.push(item)
          }
          return res
        }, [])
        return _bets
       },
      isOnline(){
        return this.$store.state.is_online
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      emitToParent(action){
        this.$emit('DialogEvent', action)
      },
      parseEntries(entries){
        return entries.split(',')
      },
      account(_id) {
        if(_id == this.user.id) {
          return "Your Bet"
        }
        return _id
      },
      getPayout() {
        var bets = this.getStats(this.bracket)
        var total = bets.reduce((res, item)=>{
          res =res + this.$IsNum(item.amount)  
          return res
        }, 0)
        return total * .90
       },
       getStats(_group) {
          for (let [key, value] of Object.entries(this.BYBRACKETS)) {
            if(_group == key) {
               var picks = this.$groupBy('pick')
               var _obj = picks(value)
              return _obj[this.pick]?_obj[this.pick]:[]
            } 
          }
          return []
       },
      getEvent: function() { 
        this.$http.post("event/get", {id: this.event_id} ).then(response => {
            response.data.item != null?this.event = response.data.item:this.event = {}
            this.getEntries()
        }).catch(e => {
        console.log(e.data)
        })
      },
      getEntries(){ 
        this.$http.post("entry/get", {event_id: this.event_id} ).then(response => {
            response.data.items != null?this.entries = response.data.items:this.entries = []
            this.getBets('all')
        }).catch(e => {
        console.log(e.data)
        })
     },
     getBets(status){  
        this.$http.post("sidebet/qry", {event_id: this.event_id, status: status} ).then(response => {
            response.data.items != null?this.bets = response.data.items:this.bets = []
            console.log("getbet", this.bets)
        }).catch(e => {
        console.log(e.data)
        })
     },
     
    },
  }
</script>