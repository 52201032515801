<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <div style="width: 100%; height: 100%; background: #fff;">
      <v-toolbar dense color="primary" dark>
        PAYMENT
        <v-spacer/>
        <v-btn small @click="emitToParent()" color="warning">Close</v-btn>
      </v-toolbar>
      <iframe :src="checkoutURL" width="100%" height="100%" title="Payment" allowfullscreen="true" frameborder="0">
    Your browser does not support iframes.
</iframe>
</div>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
        sendbtn:"convert",
         interval: {},
         loading: false,
         value:0,
         auth: false,
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    checkoutURL: {
      type: String,
      default: ""
    }
  },
  watch:{
      show(val) {
          if(!val){
            this.text=''
            this.sendbtn = "SEND"
             this.auth=false
             this.loading = false
             clearInterval(this.interval)
        }
      }
  },
  computed:{
      icon() {
         switch(this.sendbtn) {
           case "SEND":
             return "database-arrow-right"
          case "Points sent":
             return "check"
          default: 
            return ""
         }
      }
  },
  methods: {
    

    emitToParent () {
      this.$emit('DialogEvent', "")
    }
  }
}
</script>
