<template>
 <v-dialog
    v-model="show"
    persistent
    fullscreen
    >

             <v-card class="ma-1">
              <v-toolbar dense  class="pa-1" color="primary" dark>
                {{pick}} / {{bracket}}
                <!-- <v-chip color="success"> {{event.status}}</v-chip> -->
                 <v-spacer/>
                <v-icon @click="emitToParent('cancel')">mdi-close-circle</v-icon>
              </v-toolbar>
              <v-card-text>
                  <div class="mt-2 pa-1">EVENT: {{event.name}}</div>
                  <v-divider/>
                  <div class="pa-1">DATE: {{$moment(event.event_date).format("MMM, DD YYYY")}}</div>
                  <v-divider/>
                  <div class="pa-1">VENUE: {{event.venue}}</div>
                  <v-divider/>
                  <div class="pa-1">STATUS: {{event.status}}</div>
                  <v-divider/>
                  <div class="pa-1 font-weight-bold">PAYOUT: {{$money(getPayout())}} PHP</div>
                  <v-divider/>
                  
                  <table class="va-table mt-1  va-table-border-bot" cellspacing="0">
                    <tr>
                        <th class="text-left" style="background: #eaeaea; padding: 4px">Bettor</th>
                        <th class="text-center" style="background: #eaeaea; padding: 4px" >({{ALLBETS.length}})Entries</th>
                        <th class="text-center" style="background: #eaeaea; padding: 4px">Score</th>
                      </tr>
                      <tr v-for="(item, i) in SORTEDITEMS" :key="i">
                          <td :class="account(item)=='Your Bet'?'text-success':''"> {{account(item)}}  
                            <div v-if="item.status == 'Win'">
                              <v-chip color="success" x-small >
                                {{item.status }}
                            </v-chip>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="font-weight-bold" style="border-bottom: 1px solid #eee; " v-for="(name, index) in parseEntries(item.entry_names)" :key="index">
                                {{name}}
                            </div>
                            </td>
                            <td class="text-center">
                              <v-btn x-small tile color="warning" v-if="event.status == 'Open' && item.account == user.id" @click="selected=item, confirm=true"><v-icon left>mdi-close</v-icon>Cancel</v-btn>
                              <span class="font-weight-bold text-h6" v-if="event.status != 'Open'">
                                {{$IsNum(item.score)}}
                              </span>  
                             
                            </td>  
                      </tr>
                  </table>
                </v-card-text>  
             </v-card>
         <va-confirm :show="confirm" cancelbtn="close" okbtn="Confirm" :text="'Would you like to cancel this bet?' + selected.entry_names" @DialogEvent="cfEv"/>

 </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
       event_id: {
        type: Number,
        default: ()=>0
      },
      bracket: {
        type: String,
        default: ()=> ""
      },
      pick: {
        type: String,
        default: ()=> ""
      }
    },
    data: () => ({
      ticket: {},
      to_menu: false,
      viewticket: false,
      to_date:'',
      confirm: false,
      selected:{},
      cancelbtn: 'No',
      okbtn: "Ok",
      confirmText: "",
      action:'',
      event: {},
      bets:[],
      entries: []
    }),
    // created() {
    //   this.PING()
    // },
    //  mounted(){
    //    this.setLoggedIn(true)
    //     if (this.user == null) {
    //       this.$router.push('/')
    //     }else {
    //       this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
    //       this.to_date = this.$moment().format("YYYY-MM-DD")
    //       this.setData()
    //     }
    // },
    watch: {
      show(v) {
          if(v) {
            this.event = {}
            this.bets = []
            this.entries = []
            this.selected = {}
            this.getEvent()
          }
      }
    },
    computed:{
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      BYBRACKETS() {
        var allbets = this.$groupBy('bracket')
        return allbets(this.bets)
       },
       ALLBETS() {
        var _bets = this.bets.reduce((res, item)=>{
          if(item.pick == this.pick && item.bracket == this.bracket) {
            item.score = this.getScore(item.entries)
            res.push(item)
          }
          return res
        }, [])
        return _bets
       },
       SORTEDITEMS(){
        var items = this.ALLBETS
        return items.sort((a, b) =>   this.$IsNum(b.score) - this.$IsNum(a.score));
       },
      isOnline(){
        return this.$store.state.is_online
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      emitToParent(){
        this.$emit('DialogEvent', "")
      },
      cfEv(e){
        if(e == "ok") {
          this.cancelBet(this.selected)
        }
        this.confirm =false
      },
      getScore(entries){
        var arr = entries.split(',')
        var score = arr.reduce((res, item)=>{
                res = res+this.$IsNum(this.getEntryScore(item))
                return res
        }, 0)
        return score
      },
      getEntryScore(entryNO){
        var score = this.entries.reduce((res, item)=>{
            if(this.$IsNum(item.entry_no)== this.$IsNum(entryNO)) {
                res = item.score
            }
            return res
        }, 0)
        return score
      },
      parseEntries(entries){
        return entries.split(',')
      },
      account(item) {
        if(item.account == this.user.id) {
          return "Your Bet"
        }
        return item.bettor
      },
      cancelBet(item){
        let bet = {}
        bet.id = item.id
        this.$http.post("sidebet/cancel", bet).then(response => {
            if(response.data.status) {
              this.getBets('all')
              this.VA_ALERT('success', response.data.message)
            } else {
              this.VA_ALERT('error', response.data.message)
            }
        }).catch(e => {
            console.log(e.data)
        })
      },
      getPayout() {
        var bets = this.getStats(this.bracket)
        var total = bets.reduce((res, item)=>{
          res =res + this.$IsNum(item.amount)  
          return res
        }, 0)
        return total * ((100 - this.$IsNum(this.event.percentage)))/100
       },
       getStats(_group) {
          for (let [key, value] of Object.entries(this.BYBRACKETS)) {
            if(_group == key) {
               var picks = this.$groupBy('pick')
               var _obj = picks(value)
              return _obj[this.pick]?_obj[this.pick]:[]
            } 
          }
          return []
       },
      getEvent: function() { 
        this.$http.post("event/get", {id: this.event_id} ).then(response => {
            response.data.item != null?this.event = response.data.item:this.event = {}
            this.getEntries()
        }).catch(e => {
        console.log(e.data)
        })
      },
      getEntries(){ 
        this.$http.post("entry/get", {event_id: this.event_id} ).then(response => {
            response.data.items != null?this.entries = response.data.items:this.entries = []
            this.getBets('all')
        }).catch(e => {
        console.log(e.data)
        })
     },
     getBets(status){  
        this.$http.post("sidebet/qry", {event_id: this.event_id, status: status} ).then(response => {
            response.data.items != null?this.bets = response.data.items:this.bets = []
            console.log("getbet", this.bets)
        }).catch(e => {
        console.log(e.data)
        })
     },
     
    },
  }
</script>