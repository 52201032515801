<template>
  <v-app>
    <v-main :class="bgclass">
      <core-toolbar  v-if="IsLoggedIn"/>
      <core-drawer v-if="IsLoggedIn"/>
      <core-view />
      <core-footer v-if="IsLoggedIn"/>
    </v-main>
       <v-snackbar
      v-model="showAlert"
      :color="alertColor"
      :timeout="3000"
       :top="'top'"
     >
     <div class="text-center text-h6 mt-1 mb-1">
        {{alertText }}
     </div>
    </v-snackbar>
     <v-overlay :value="LOADING">
      <p class="text-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <br/>
        <i>{{LOADING_TEXT}}</i>
      </p>
    </v-overlay>
    <!-- <va-machine :show="OPENMACHINE" @DialogEvent="mEvent"/> -->
  </v-app>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
  name: 'App',
  components: {
  },

  data: () => ({
  }),
  mounted () {
    //this.onResponsiveInverted()
    //window.addEventListener('resize', this.onResponsiveInverted)
  },
  computed:{
    WATCHDRAW(){
      if(this.$route.name != "Login"){
         return this.$store.state.watchdraw
      } else {
        return false
      }
    },
    OPENMACHINE(){
      return this.$store.state.open_machine
    },
    LOADING(){
      return this.$store.state.overlay_loading
    },
     LOADING_TEXT(){
      return this.$store.state.overlay_loading_text
    },
      showAlert: {
        get () {
          return this.$store.state.show_alert
          },
          set (val) {
            this.setAlert(val)
          }
      },
      alertText() {
        return this.$store.state.alert_text
      },
      alertColor(){
          return this.$store.state.alert_color
      },
      windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      IsLoggedIn(){
         return this.$store.state.is_logged
      },
      bgclass() {
          if(this.$store.state.is_logged){
            return "logged-page"
          } else {
            return "login-page"
          }
      }
  },
  methods: {
    ...mapMutations(['setDrawer', 'setResponsive', 'setAlert', 'setOpenMachine', "setWatchDraw"]),
     mEvent() {
        this.setOpenMachine(false)
     }
  }

};
</script>
<style>
/* file:///android_asset/www/ */
/* .logbox {
  background: #0B3447 url('/img/banner-img.png') no-repeat center bottom;; 
} */
/* .logged-page  {
    background: url('/img/banner-bg.png') no-repeat center bottom; 
  } */
  @font-face {
        font-family: Potato;
        src: local("fonts"),url("/fonts/Potato_sans-Black.otf");
      }  
  .va-entry div {
      padding: 3px;
      border-bottom: 1px solid #f0f0f0;
  }
  .login-page {
    background:  url('/img/banner-img2.png') no-repeat center bottom; 
    background-size: 240px 300px;
  }
  #va-drawer, #va-toolbar {
    text-transform: uppercase !important;
    font-family: 'Montserrat', sans-serif !important;
  }
   #va-drawer {
     z-index: 9;
   }
  .va-table {
      width: 100%;
  }
  td.even-td {
    background-color: #fff;
  }
  .va-not-reponsive {
    padding-left: 255px;
  }
  .va-table tbody td {
    font-size: .8em !important;
  }
  .va-table-border-bot th,   .va-table-border-bot td {
    border-bottom: 1px solid #dedede;
  }
  @media print {
    
    .va-table  thead td {
    font-size: .45em !important;
  }
   .va-table  tbody td {
    font-size: .55em !important;
    height: 10px !important;
  }
   .va-table  tfoot td {
    font-size: .45em !important;
  }
}
  .va-underline {
    border-bottom: 1px solid #F96332;
  }
  .v-select__slot, .v-text-field__slot {
      font-size: .75em !important;
  }
  .v-btn__content {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .va-right input{
    text-align: right;
  }
  .va-input {
     width: 70px !important;
     background: #efefef;
     text-align: center;
  }
.text-white {
  color: #fff;
}
.text-secondary {
  color: #19365A;
}
.text-success {
  color: #5cb860;
}
.text-error {
  color: #f55a4e;
}
.text-gold {
  color: #E38011;
}
.text-primary {
  color: #052A51;
}
.text-warning {
  color: #ffa21a;
}
.text-info {
  color: #00d3ee;
}
.text-accent {
  color: #82B1FF;
}

.inner-table td {
  border-bottom: 0 !important;
  width: 50px !important;
  text-align: center;
  border-right:  1px solid #eee;
}

/* Drawer */
#va-drawer .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
#va-drawer .v-expansion-panels .v-expansion-panel {
  background: none !important;
  /* border-radius: 0 !important;
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;  */
}
#va-drawer .v-expansion-panel::before {
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;

}
.lg-font {
  font-family: 'OpenSans-Bold';
  font-size: 1.3em;
}
.sm-font {
  font-size: .7em;
}
.md-font {
  font-size: .8em;
}

.print_table3  td {
  border-bottom: 1px solid #eee;
  padding: 12px;
}
.print_table3 .inner-table  td {
  padding: 0;
}
.div-header {
  background: #5EAD79;
  color:#fff;
  padding: 4px 10px;
}
.pos .v-expansion-panel-content__wrap {
    padding: 7px;;
}
.text-shadow {
  text-shadow: 2px 2px #232323;
} 
.td-odd {
  background: #f0f0f0;
}
.td-even {
  background: #e7e7e7;
}
.entrycon {
  height: 300px;
  background: #fff;
  overflow-y: scroll;
}
.regla_wrap {
  width: 1280px;
}
.regla {
  width: 48px;
  float: left;
  border: 1px solid #fff;
  background: #f1f1f1;
  padding: 3px;
  text-align: center;
}
.playbtn {
  background: #141b25 !important;
  border: 3px solid #fdca0d;
}
._numbtn {
  background: #141b25 !important;
text-shadow: 2px 2px #333;
  border: 1px solid #d83725;
}
.rescard {
  background:  url('/img/sball.png') no-repeat center top; 
  background-color: #042632 !important;
   background-size: 100%;
}
.rescard-side {
  /* background:  url('/img/sidebg.png') no-repeat center top; 
  background-size: 100%;
  background-color: #042632 !important; */
}
.ev-date {
  font-size: 1em !important;
}
.ev-venue {
  font-size: .9em !important;
}
.ev-title {
  font-size: 1em !important;
  font-family: 'Montserrat', sans-serif !important;

}
.hero1 {
  width: 100%;
  height: 150px;
  background: url('/img/winner.png') no-repeat center bottom;
  background-size: 100%;
} 
.hero2 {
  width: 100%;
  height: 150px;
  background: url('/img/ng.png') no-repeat center bottom;
  background-size: 100%;
} 
.hero3 {
  width: 100%;
  height: 150px;
  background: url('/img/hero3.png') no-repeat center bottom;
  background-size: 100%;
} 
.potato-text {
  font-family: 'Potato',  sans-serif  !important;
  letter-spacing:  .2em;
}
.hero2 .btn {
  font-size: 2em !important;
  text-shadow: 2px 2px #fff;
}
         
.btn-grad-info {
            background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
            /* margin: 10px;
            padding: 15px 45px; */
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            border-radius: 10px;
          }

          .btn-grad-info:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }

.btn-grad-primary {
            background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
            /* margin: 10px;
            padding: 15px 45px; */
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            border-radius: 10px;
          }

          .btn-grad-primary:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }
         
</style>