import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//import axios from './plugins/axios'

//Plugins
import './plugins'
// Components
import './components'


import VueSession from 'vue-session'
import router from '@/router'
import store from '@/store'
import { API_MIXINS } from '@/mixins/api_mixins.js'
import 'animate.css';
import { DATA_SETTERS } from '@/mixins/data_setters.js'
import fastclick from 'fastclick'
fastclick.attach(document.body)
import VueFriendlyIframe from 'vue-friendly-iframe';
import LoadScript from 'vue-plugin-load-script'

  Vue.use(LoadScript)
Vue.use(VueFriendlyIframe);
Vue.use(require('vue-moment'))
Vue.use(VueSession)
Vue.config.productionTip = false
Vue.mixin(API_MIXINS)
Vue.mixin(DATA_SETTERS)
window.console = console
console.log = function () {}
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
